import { useMemo } from 'react';
import { useRbTexts } from 'remote-state/applicationHooks';
import { formatTranslation } from 'common/utils/translation';

export default function useTexts(editorType) {
  const texts = useRbTexts();
  const modal = useMemo(
    () => ({
      NOTE: {
        exitPage: {
          title: texts?.['spaces.resolutionPanel.journey.notes.cancelPrompt.title'],
          description: texts?.['spaces.resolutionPanel.journey.notes.cancelPrompt.text'],
        },
        error: {
          title: (message) => message?.title || texts?.['spaces.resolutionPanel.journey.notes.errorPrompt.title'],
          description: (message) =>
            message?.description || texts?.['spaces.resolutionPanel.journey.notes.errorPrompt.text'],
          createDescription: texts?.['spaces.resolutionPanel.journey.notes.createErrorPrompt.text'],
          deleteDescription: texts?.['spaces.resolutionPanel.journey.notes.deleteErrorPrompt.text'],
          editDescription: texts?.['spaces.resolutionPanel.journey.notes.editErrorPrompt.text'],
          btnOkText: (btnTexts) => btnTexts?.okBtn || texts?.['spaces.requiredFieldsModal.OkText'],
        },
        cancel: {
          title: (message) => message?.title || texts?.['spaces.resolutionPanel.journey.notes.cancelPrompt.title'],
          unsavedTitle: texts?.['spaces.resolutionPanel.journey.notes.cancelButton.cancelPrompt.title'],
          description: (message) =>
            message?.description || texts?.['spaces.resolutionPanel.journey.notes.cancelPrompt.text'],
          editToCreateText: texts?.['spaces.resolutionPanel.journey.notes.cancelPrompt.editToCreate.text'],
          editToEditText: texts?.['spaces.resolutionPanel.journey.notes.cancelPrompt.editToEdit.text'],
          btnOkText: (btnTexts) => btnTexts?.okBtn || texts?.['spaces.requiredFieldsModal.OkText'],
        },
        delete: {
          title: texts?.['spaces.resolutionPanel.journey.notes.deletePrompt.title'],
          description: texts?.['spaces.resolutionPanel.journey.notes.deletePrompt.text'],
        },
      },
      MESSAGE: {
        exitPage: {
          title: texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.title'],
          description: texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.text'],
          btnOkText: texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.OkText'],
        },
        success: {
          description: texts?.['spaces.resolutionPanel.journey.message.toaster.success'],
        },
        error: {
          toastError: texts?.['spaces.resolutionPanel.journey.message.toaster.error'],
          title: (message) => message?.title || texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.title'],
          btnOkText: (btnTexts) =>
            btnTexts?.okBtn || texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.OkText'],
          description: (message) =>
            message?.description || texts?.['spaces.resolutionPanel.journey.message.errorPrompt.text'],
        },
        cancel: {
          title: (message) => message?.title || texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.title'],
          unsavedTitle: texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.title'],
          description: (message) =>
            message?.description || texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.text'],
          btnOkText: (btnTexts) =>
            btnTexts?.okBtn || texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.OkText'],
          editToCreateText: texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.text'],
          editToCreateTitle: texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.title'],
          editToCreateCancelBtnText: texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.CancelText'],
          editToCreateOkBtnText: texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.OkText'],
          btnCancelText: texts?.['spaces.resolutionPanel.journey.message.cancelPrompt.CancelText'],
        },
      },

      RESOLUTION: {
        exitPage: {
          title: texts?.['spaces.resolutionPanel.journey.solutionResolution.cancelPrompt.title'],
          description: texts?.['spaces.resolutionPanel.journey.solutionResolution.cancelPrompt.text'],
          btnOkText: texts?.['spaces.resolutionPanel.journey.solutionResolution.ok.text'],
          btnCancelText: texts?.['spaces.resolutionPanel.journey.solutionResolution.cancel.text'],
        },
        success: {
          description: texts?.['spaces.resolutionPanel.journey.solutionResolution.toaster.success'],
        },
        error: {
          title: (message) => message?.title || texts?.['spaces.resolutionPanel.journey.notes.errorPrompt.title'],
          btnOkText: (btnTexts) => btnTexts?.okBtn || texts?.['spaces.requiredFieldsModal.OkText'],
          description: (message) =>
            message?.description || texts?.['spaces.resolutionPanel.journey.solutionResolution.errorPrompt.text'],
          editDescription: texts?.['spaces.resolutionPanel.journey.solutionResolution.errorPrompt.text'],
        },
        cancel: {
          title: (message) =>
            message?.title || texts?.['spaces.resolutionPanel.journey.solutionResolution.cancelPrompt.title'],
          description: (message) =>
            message?.description || texts?.['spaces.resolutionPanel.journey.solutionResolution.cancelPrompt.text'],
          btnOkText: (btnTexts) =>
            btnTexts?.okBtn || texts?.['spaces.resolutionPanel.journey.solutionResolution.ok.text'],
          btnCancelText: texts?.['spaces.resolutionPanel.journey.solutionResolution.cancel.text'],
        },
        requiredFields: {
          title: texts?.['spaces.resolutionPanel.journey.solutionResolution.requiredFields.title'],
          description: texts?.['spaces.resolutionPanel.journey.solutionResolution.requiredFields.text'],
        },
      },
      ACTIVITY: {
        exitPage: {
          title: texts?.['spaces.resolutionPanel.journey.activity.cancelPrompt.title'] || 'Discard changes?',
          description:
            texts?.['spaces.resolutionPanel.journey.activity.cancelPrompt.text'] ||
            "Your Activity Card changes won't be saved",
          btnOkText: texts?.['spaces.resolutionPanel.journey.activity.ok.text'] || 'Discard',
          btnCancelText: texts?.['spaces.resolutionPanel.journey.activity.cancel.text'] || 'Cancel',
        },
        success: {
          description: texts?.['spaces.resolutionPanel.journey.activity.toaster.success'] || 'Activity created!',
        },
        error: {
          title: (message) =>
            message?.title ||
            texts?.['spaces.resolutionPanel.journey.activity.errorPrompt.title'] ||
            'Something happened',
          btnOkText: (btnTexts) => btnTexts?.okBtn || texts?.['spaces.requiredFieldsModal.OkText'] || 'Leave',
          description: (message) =>
            message?.description ||
            texts?.['spaces.resolutionPanel.journey.activity.errorPrompt.text'] ||
            'Something went wrong, refresh or try again later.',
          editDescription:
            texts?.['spaces.resolutionPanel.journey.activity.errorPrompt.text'] ||
            'Something went wrong, refresh or try again later.',
          deleteDescription:
            texts?.['spaces.resolutionPanel.journey.activity.deleteErrorPrompt.text'] ||
            'Failed to delete the activity. Please contact your administrator for more information.',
        },
        cancel: {
          title: (message) =>
            message?.title ||
            texts?.['spaces.resolutionPanel.journey.activity.cancelPrompt.title'] ||
            'Discard changes?',
          description: (message) =>
            message?.description ||
            texts?.['spaces.resolutionPanel.journey.activity.cancelPrompt.text'] ||
            "Your Activity Card changes won't be saved",
          btnOkText: (btnTexts) =>
            btnTexts?.okBtn || texts?.['spaces.resolutionPanel.journey.activity.ok.text'] || 'Discard',
          btnCancelText: texts?.['spaces.resolutionPanel.journey.activity.cancel.text'] || 'Cancel',
        },
        delete: {
          title: texts?.['spaces.resolutionPanel.journey.activity.deletePrompt.title'] || 'Delete Activity',
          description:
            texts?.['spaces.resolutionPanel.journey.activity.deletePrompt.text'] ||
            'Are you sure you want to delete this activity?',
        },
      },
      btnProceedText: texts?.['spaces.resolutionPanel.journey.notes.proceed'],
      btnCancelText: (btnTexts) => btnTexts?.cancelBtn || texts?.['spaces.requiredFieldsModal.CancelText'],
    }),
    [texts],
  );
  const componentTexts = {
    getColumnText: (displayKey) => texts?.[displayKey],
    via: texts?.['spaces.via'],
    cancel: texts?.['spaces.header.actions.deleteTicket.cancel'],
    save: texts?.['spaces.resolutionPanel.journey.notes.save'],
    send: texts?.['spaces.resolutionPanel.journey.messages.send'],
    summaryFilter: texts?.['spaces.resolutionPanel.journey.dataFilters.select.summary'],
    activitiesFilter: texts?.['spaces.resolutionPanel.journey.dataFilters.select.activities'] || 'Activities',
    auditLogItemsFilter: texts?.['spaces.resolutionPanel.journey.dataFilters.select.auditLogItems'],
    expandSwitch:
      texts?.['spaces.resolutionPanel.journey.dataFilters.select.expandSwitch'] ||
      'Expand all messages & notes by default',
    auditLogAdded: texts?.['spaces.resolutionPanel.journey.auditLog.added'],
    auditLogRemoved: texts?.['spaces.resolutionPanel.journey.auditLog.removed'],
    auditLogLoadMore: texts?.['spaces.resolutionPanel.journey.auditLog.loadMore'],
    auditLogLoading: texts?.['spaces.resolutionPanel.journey.auditLog.loading'],
    auditLogBy: texts?.['spaces.resolutionPanel.journey.auditLog.by'],
    auditLogNo: texts?.['spaces.resolutionPanel.journey.auditLog.no'],
    auditLogRecords: texts?.['spaces.resolutionPanel.journey.auditLog.records'],
    fullJourneyFilter: texts?.['spaces.resolutionPanel.journey.dataFilters.select.fullJourney'],
    journeyTab: texts?.['spaces.resolutionPanel.journey.dataFilters.tabs.journey'],
    attachmentsTab: texts?.['spaces.resolutionPanel.journey.dataFilters.tabs.attachments'],
    workflowTab: texts?.['spaces.resolutionPanel.journey.dataFilters.tabs.workflow'],
    ticketDetailsTab: texts?.['spaces.resolutionPanel.journey.dataFilters.tabs.ticketDetails'],
    relatedItemsTab: texts?.['spaces.resolutionPanel.journey.dataFilters.tabs.relatedItems'] || 'Related Items',
    footerNoteItem: texts?.['spaces.resolutionPanel.footer.note'],
    footerMessageItem: texts?.['spaces.resolutionPanel.footer.message'],
    footerAttachmentItem: texts?.['spaces.resolutionPanel.footer.attachment'],
    footerResolutionItem: texts?.['spaces.resolutionPanel.footer.resolution'],
    showLessText: texts?.['spaces.resolutionPanel.journey.logDescription.fieldChange.description.showLess'],
    showMoreText: texts?.['spaces.resolutionPanel.journey.logDescription.fieldChange.description.showMore'],
    noneText: texts?.['spaces.resolutionPanel.journey.logDescription.fieldChange.description.none'],
    logDescriptionError: texts?.['spaces.resolutionPanel.journey.logDescription.error'],
    meText: texts?.['spaces.resolutionPanel.journey.logMetaData.username.me'],
    openEvent: texts?.['spaces.resolutionPanel.journey.logTitle.event.open'],
    closeEvent: texts?.['spaces.resolutionPanel.journey.logTitle.event.close'],
    reopenEvent: texts?.['spaces.resolutionPanel.journey.logTitle.event.reopen'],
    archiveEvent: texts?.['spaces.resolutionPanel.journey.logTitle.event.archive'],
    restoreEvent: texts?.['spaces.resolutionPanel.journey.logTitle.event.restore'],
    viewEvent: texts?.['spaces.resolutionPanel.journey.logTitle.event.view'],
    dueDateEvent: texts?.['spaces.resolutionPanel.journey.logTitle.event.pastDueEvent'],
    noActionDetectedText: texts?.['spaces.resolutionPanel.journey.logTitle.noAction'],
    changedText: texts?.['spaces.resolutionPanel.journey.changed'],
    theTicket: texts?.['spaces.resolutionPanel.journey.theTicket'],
    reasonText: texts?.['spaces.resolutionPanel.journey.reason'],
    ticketText: texts?.['spaces.resolutionPanel.journey.ticket'],
    statusText: texts?.['spaces.resolutionPanel.journey.status'],
    priorityText: texts?.['spaces.resolutionPanel.journey.priority'],
    assignmentText: texts?.['spaces.resolutionPanel.journey.assignment'],
    categoryText: texts?.['spaces.resolutionPanel.journey.category'],
    subCategoryText: texts?.['spaces.resolutionPanel.journey.subCategory'],
    thirdLevelCategoryTitle: texts?.['spaces.resolutionPanel.journey.3rdLevelCategory'],
    primaryCategoryText: texts?.['spaces.resolutionPanel.journey.primaryCategory'],
    secondaryCategoryText: texts?.['spaces.resolutionPanel.journey.secondaryCategory'],
    thirdLevelCategoryText: texts?.['spaces.resolutionPanel.journey.thirdLevelCategory'],
    descriptionText: texts?.['spaces.resolutionPanel.journey.description'],
    mainAssetText: texts?.['spaces.template.fields.main_asset'],
    mainCiText: texts?.['spaces.template.fields.main_ci'],
    attachmentLinkText: texts?.['spaces.resolutionPanel.footer.note.attachmentLink'],
    deescalation: texts?.['spaces.resolutionPanel.journey.deescalation'],
    escalation: texts?.['spaces.resolutionPanel.journey.escalation'],
    notificationSent: texts?.['spaces.resolutionPanel.journey.notificationSent'],
    actionItemNotificationSent: texts?.['spaces.resolutionPanel.journey.actionItemNotificationSent'],
    closedInDueDate: texts?.['spaces.resolutionPanel.journey.closedinduedate'],
    closedNotInDueDate: texts?.['spaces.resolutionPanel.journey.closednotinduedate'],
    newArticleCreated: texts?.['spaces.resolutionPanel.journey.newarticlecreated'],
    solutionEdited: texts?.['spaces.resolutionPanel.journey.solutionedited'],
    resolutionEdited: texts?.['spaces.resolutionPanel.journey.resolutionedited'],
    notificationView: texts?.['spaces.resolutionPanel.journey.notification.view'],
    noteText: texts?.['spaces.resolutionPanel.footer.note'],
    unassignedText: texts?.['spaces.header.current-assigned.unassigned'],
    emptyAuditLogValueText: texts?.['spaces.template.fields.text.none'],
    requestUserTexts: texts?.['spaces.template.fields.request_user'],
    richTextEditorPlaceholder: texts?.['spaces.resolutionPanel.journey.actionLine.placeholder'],
    shareWithRequestUser: texts?.['spaces.resolutionPanel.journey.notes.shareWithRequestUser'],
    shareWithReqUserTitle: texts?.['spaces.resolutionPanel.journey.notes.ByDefaultShareWithRequestUser'],
    sharedText: texts?.['spaces.resolutionPanel.journey.notes.sharedWithRequestUser'],
    assetText: texts?.['spaces.relatedItems.journey.asset'] || 'Asset',
    ciText: texts?.['spaces.relatedItems.journey.ci'] || 'CI',
    unshareTooltipText: () => {
      if (editorType === 'NOTE') {
        return texts?.['spaces.resolutionPanel.journey.notes.unshareTooltip'];
      }
      if (editorType === 'RESOLUTION') {
        return texts?.['spaces.resolutionPanel.journey.solution.unshareTooltip'];
      }
      return '';
    },

    getRelationshipText: (relationship) =>
      texts?.[`spaces.relatedItems.linkProcess.relationship.${relationship}`] || relationship,

    byRequestUserText: texts?.['spaces.resolutionPanel.journey.notes.byRequestUser'],
    internalText: texts?.['spaces.resolutionPanel.journey.notes.internal'],
    deleteText: texts?.['spaces.header.actions.deleteTicket'],
    deletedText: texts?.['spaces.resolutionPanel.journey.notes.deleted'],
    editText: texts?.['spaces.resolutionPanel.journey.notes.edit'],
    editedText: texts?.['spaces.resolutionPanel.journey.notes.edited'],
    reply: texts?.['spaces.resolutionPanel.journey.notes.reply'],
    replyAll: texts?.['spaces.resolutionPanel.journey.messages.actions.replyAll'],
    forward: texts?.['spaces.resolutionPanel.journey.messages.actions.forward'],
    attachmentsAdded: texts?.['spaces.resolutionPanel.journey.attachments.added'],
    attachmentAdded: texts?.['spaces.resolutionPanel.journey.attachment.added'] || 'Attachment added',
    attachmentsDeleted: texts?.['spaces.resolutionPanel.journey.attachments.deleted'],
    attachmentsDownloaded: texts?.['spaces.resolutionPanel.journey.attachments.downloaded'],
    sender: texts?.['spaces.resolutionPanel.journey.messages.header.sender'],
    recipient: texts?.['spaces.resolutionPanel.journey.messages.header.recipient'],
    cc: texts?.['spaces.resolutionPanel.journey.messages.header.cc'],
    bcc: texts?.['spaces.resolutionPanel.journey.messages.header.bcc'],
    subject: texts?.['spaces.resolutionPanel.journey.messages.header.subject'],
    outgoingEmail: texts?.['spaces.resolutionPanel.journey.messages.footer.outgoingEmail'],
    incomingEmail: texts?.['spaces.resolutionPanel.journey.messages.footer.incomingEmail'],
    sentToChildSrs: texts?.['spaces.resolutionPanel.journey.messages.header.sentToChildSrs'],
    resolutionPlaceholder: texts?.['spaces.resolutionPanel.journey.solutionResolution.resolutionPlaceholder'],
    solutionPlaceholder: texts?.['spaces.resolutionPanel.journey.solutionResolution.solutionPlaceholder'],
    resolutionStatus: texts?.['spaces.resolutionPanel.journey.solutionresolution.resolutionStatus'],
    closureInformation: texts?.['spaces.resolutionPanel.journey.solutionresolution.closureInformation'],
    assignee: texts?.['spaces.resolutionPanel.journey.solutionresolution.assignee'],
    changedToMe: texts?.['spaces.resolutionPanel.journey.solutionresolution.changedtome'],
    solution: texts?.['spaces.resolutionPanel.journey.solutionresolution.solution'],
    resolution: texts?.['spaces.resolutionPanel.journey.solutionresolution.resolution'],
    sharedWithRequestUser: texts?.['spaces.resolutionPanel.journey.solutionresolution.sharedWithRequestUser'],
    reAssignmentText: texts?.['spaces.resolutionPanel.journey.reAssignment'],
    outOfOfficeText: texts?.['spaces.resolutionPanel.journey.outOfOffice'],
    migrationTicketEventText: texts?.['spaces.resolutionPanel.journey.migrationTicket'],
    mergeReason: texts?.['spaces.resolutionPanel.journey.mergeReason'] || 'Merge reason',
    conversionTicketEventText: texts?.['spaces.resolutionPanel.journey.conversionTicket'] || 'was converted into',
    viewHistoryText: texts?.['spaces.viewHistory'],
    activityAdded: texts?.['spaces.resolutionPanel.journey.activity.added'] || 'Activity added',
    activityDeleted: texts?.['spaces.resolutionPanel.journey.activity.deleted'] || 'Activity deleted',
    linkedAsText: texts?.['spaces.relatedItems.journey.linkedAs'] || 'linked as',
    unlinkedText: texts?.['spaces.relatedItems.journey.unlinked'] || 'unlinked',
    subjectFormat: (title, srId) =>
      formatTranslation(texts?.['spaces.resolutionPanel.journey.messages.header.subjectFormat'], [
        { placeHolder: '{title}', value: title },
        { placeHolder: '{srId}', value: srId },
      ]),
    modal,
    duplicatedByEvent: texts?.['spaces.resolutionPanel.journey.logTitle.event.duplicatedBy'] ?? 'Service Record duplicated by',
    duplicatedFromEvent: texts?.['spaces.resolutionPanel.journey.logTitle.event.duplicatedFrom'] ?? 'Service Record duplicated from',
  };

  return componentTexts;
}
