export const actionEnums = {
  // general ticket enums (1-10)
  OPERATION_CREATE: 1,
  OPERATION_DELETE: 2,

  // field changes : (11-30)
  FIELD_STATUS: 11,
  FIELD_PRIORITY: 12,
  FIELD_ASSIGNMENT: 13,
  FIELD_CATEGORY: 14,
  FIELD_DESCRIPTION: 15,
  FIELD_REGULAR: 16,
  FIELD_CUSTOM_COLUMN: 17,
  FIELD_DUE_DATE: 18,
  FIELD_REASSIGNMENT: 19,
  FIELD_OUT_OF_OFFICE: 20,

  // ticket events : (31-50)
  EVENT_CLOSE: 31,
  EVENT_REOPEN: 32,
  EVENT_DUE_DATE: 33,
  EVENT_VIEW: 34,

  //note events(51-60)
  NOTE_CREATE: 51,
  NOTE_DELETE: 52,
  NOTE_UPDATE: 53,
  NOTE_ACTION: 60,

  //attachment events(61-70)
  ATTACHMENTS_ADDED: 61,
  ATTACHMENTS_DELETED: 62,
  ATTACHMENTS_DOWNLOADED: 63,
  ACTION_ITEM_ATTACHMENTS_ADDED: 64,
  ACTION_ITEM_ATTACHMENTS_DELETED: 65,

  //escalation events(71-80)
  ESCALATION_RESET: 71,
  ESCALATION_CHANGED: 72,

  //notification events(81-90)
  NOTIFICATION: 81,
  ESCALATION_MESSAGE: 94,

  //message events(91-100)
  MESSAGE_SENT: 91,
  AI_MESSAGE_SEND: 92,
  MESSAGE_RECEIVED: 93,
  IM_MESSAGE: 95,
  SMS_MESSAGE: 96,
  ITIL_MESSAGE: 97,
  MESSAGE_ACTION: 100,

  //solution resolution events(101-120)
  SOLUTION_RESOLUTION_CREATED: 101, //after create/update      ||in summary
  SOLUTION_SR_RESOLVED: 102, //after create     ||in summary
  SOLUTION_NEW_ARTICLE_CREATED: 103, //after create/update     ||in summary
  SOLUTION_UPDATED: 104, //after update
  RESOLUTION_UPDATED: 105, //after update
  SOLUTION_STATUS_CHANGED: 106, //after update
  SOLUTION_CLOSURE_INFORMATION_CHANGED: 107, //after update
  SOLUTION_ASSIGNEE_CHANGED: 108,
  ACTION_ITEM_FIELD_CHANGE: 109,
  ACTION_ITEM_STATUS_COMPLETED: 110,
  ACTION_ITEM_STATUS_ENABLED: 111,
  ACTION_ITEM_STATUS_DISABLED: 112,
  ACTION_ITEM_DATE_FIELD_CHANGE: 113,
  ACTION_ITEM_MULTISELECT_VALUE_ADDED: 114,
  ACTION_ITEM_MULTISELECT_VALUE_REMOVED: 115,

  //migrations events(121-130)
  TICKET_MIGRATION_HISTORY: 121,

  //activities
  ACTIVITY_CREATED: 131,
  TICKET_CONVERSION: 141,

  //related items
  RELATED_ITEM_SR_CREATED: 151,
  RELATED_ITEM_SR_DELETED: 152,
  RELATED_ITEM_ASSET_CREATED: 153,
  RELATED_ITEM_ASSET_DELETED: 154,
  RELATED_ITEM_CI_CREATED: 155,
  RELATED_ITEM_CI_DELETED: 156,

  //archive / restore
  SR_ARCHIVED: 160,
  SR_RESTORED: 161,

  //duplicated by/from
  SR_DUPLICATED_BY_TICKET: 162,
  SR_DUPLICATED_FROM_TICKET: 163,

  //merge
  SERVICE_RECORDS_MERGED: 170,
  MERGE_REASON: 171,
};

export const RICH_TEXT_TYPES = [
  actionEnums.FIELD_REGULAR,
  actionEnums.FIELD_DESCRIPTION,
  actionEnums.NOTE_CREATE,
  actionEnums.NOTE_DELETE,
  actionEnums.NOTE_UPDATE,
  actionEnums.MESSAGE_SENT,
  actionEnums.AI_MESSAGE_SEND,
  actionEnums.MESSAGE_RECEIVED,
  actionEnums.SOLUTION_RESOLUTION_CREATED,
  actionEnums.SOLUTION_UPDATED,
  actionEnums.RESOLUTION_UPDATED,
  actionEnums.IM_MESSAGE,
  actionEnums.SMS_MESSAGE,
  actionEnums.ITIL_MESSAGE,
];
export const EDITABLE_TEXT_TYPES = [actionEnums.NOTE_CREATE, actionEnums.NOTE_UPDATE];
export const HIDE_KEBAB_MENU_TYPES = [
  actionEnums.NOTE_DELETE,
  actionEnums.NOTE_UPDATE,
  actionEnums.MESSAGE_SENT,
  actionEnums.AI_MESSAGE_SEND,
  actionEnums.MESSAGE_RECEIVED,
  actionEnums.IM_MESSAGE,
  actionEnums.SMS_MESSAGE,
  actionEnums.ITIL_MESSAGE,
];
export const READ_ONLY_HAS_HEADER = [
  actionEnums.MESSAGE_SENT,
  actionEnums.AI_MESSAGE_SEND,
  actionEnums.MESSAGE_RECEIVED,
  actionEnums.IM_MESSAGE,
  actionEnums.SMS_MESSAGE,
  actionEnums.ITIL_MESSAGE,
];
export const HOVER_EFFECTS = [
  actionEnums.NOTE_CREATE,
  actionEnums.MESSAGE_SENT,
  actionEnums.AI_MESSAGE_SEND,
  actionEnums.MESSAGE_RECEIVED,
  actionEnums.IM_MESSAGE,
  actionEnums.SMS_MESSAGE,
  actionEnums.ITIL_MESSAGE,
];
export const NOTE_EVENTS = [
  actionEnums.NOTE_CREATE,
  actionEnums.NOTE_DELETE,
  actionEnums.NOTE_UPDATE,
  actionEnums.SOLUTION_UPDATED,
  actionEnums.RESOLUTION_UPDATED,
];
export const MESSAGE_EVENTS = [
  actionEnums.MESSAGE_SENT,
  actionEnums.AI_MESSAGE_SEND,
  actionEnums.MESSAGE_RECEIVED,
  actionEnums.IM_MESSAGE,
  actionEnums.SMS_MESSAGE,
  actionEnums.ITIL_MESSAGE,
];
export const DELETE_ATTACHMENT_ENABLED = [
  actionEnums.NOTE_CREATE,
  actionEnums.NOTE_UPDATE,
  actionEnums.SOLUTION_RESOLUTION_CREATED,
  actionEnums.SOLUTION_UPDATED,
  actionEnums.RESOLUTION_UPDATED,
];
export const HAS_FOOTER = [actionEnums.NOTE_UPDATE, actionEnums.SOLUTION_UPDATED];
export const RESOLUTION_EVENTS = [actionEnums.SOLUTION_RESOLUTION_CREATED];
export const RESOLUTION_UPDATING_EVENTS = [actionEnums.SOLUTION_UPDATED, actionEnums.RESOLUTION_UPDATED];
export const RELATED_ITEMS_EVENTS = [
  actionEnums.RELATED_ITEM_SR_CREATED,
  actionEnums.RELATED_ITEM_SR_DELETED,
  actionEnums.RELATED_ITEM_ASSET_CREATED,
  actionEnums.RELATED_ITEM_ASSET_DELETED,
  actionEnums.RELATED_ITEM_CI_CREATED,
  actionEnums.RELATED_ITEM_CI_DELETED,
];
export const FILTER_ENUMS = {
  SUMMARY: 'summary',
  FULL_JOURNEY: 'fullJourney',
  ACTIVITIES: 'activities',
  ATTACHMENTS: 'attachments',
  AUDIT_LOG_ITEMS: 'auditLogItems',
  EXPAND_SWITCH: 'expandSwitch',
};
