export const initialNote = {
  replyTo: 0,
  noteText: '',
  attachments: [],
  inlineImages: [],
  shareWithReqUser: false,
};

export const initialMessage = {
  cc: [],
  bcc: [],
  invalidRecipients: [],
  sender: {},
  subject: '',
  recipients: [],
  attachments: [],
  inlineImages: [],
  messageText: '',
  isCcExpanded: false,
  isBccExpanded: false,
  isRecipientsFocused: true,
  isCcFocused: false,
  isBccFocused: false,
  shouldAddCc: true,
};

export const initialResolution = {
  closureInformation: 0,
  closureInformationCaption: '',
  status: 0,
  statusCaption: '',
  assignee: null,
  author: '',
  articleName: '',
  linkToKnowledgeBase: '',
  isLinkToKnowledgeBase: true,
  shareWithReqUser: false,
  isShareWithReqUserChanged: false,
  isDisabledJourneyLog: false,
  resolutionText: '',
  solutionText: '',
  attachments: [],
  inlineImages: [],
};

export const initialActivity = {
  user: null,
  time: null,
  description: '',
};
